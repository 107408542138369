export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true
  },
  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx-home-circle',
    link: '/'
  },
  {
    id: 25,
    label: 'menuitems.admin.text',
    icon: 'mdi mdi-account-key',
    subItems: [
      {
        id: 26,
        label: 'menuitems.admin.users',
        link: '/users',
        parentId: 25
      },
      {
        id: 27,
        label: 'menuitems.admin.roles',
        link: '/roles',
        parentId: 25
      }
    ]
  },
  {
    id: 3,
    label: 'menuitems.apps.text',
    isTitle: true
  },
  {
    id: 4,
    label: 'menuitems.myevent.text',
    icon: 'bx-laptop',
    subItems: [
      {
        id: 5,
        label: 'menuitems.myevent.list.setting',
        link: '/event/setting',
        parentId: 4
      },
      {
        id: 33,
        label: 'menuitems.myevent.list.feed',
        link: '/event/feed',
        parentId: 4
      },
      {
        id: 32,
        label: 'menuitems.myevent.list.forms',
        link: '/event/form',
        parentId: 4
      },
      {
        id: 9,
        label: 'menuitems.myevent.list.customization',
        link: '/event/customization',
        parentId: 4
      },
      {
        id: 8,
        label: 'menuitems.myevent.list.certificate',
        link: '/event/certificate',
        parentId: 4
      },
      {
        id: 28,
        label: 'menuitems.myevent.list.templateEmail',
        link: '/event/template-email',
        parentId: 4
      },
      {
        id: 7,
        label: 'menuitems.myevent.list.integration',
        link: '/event/integration',
        parentId: 4
      },
      {
        id: 6,
        label: 'menuitems.myevent.list.menu',
        link: '/event/menu',
        parentId: 4
      },
      {
        id: 18,
        label: 'menuitems.myevent.list.terms-conditions',
        link: '/event/terms-conditions',
        parentId: 4
      }
    ]
  },
  {
    id: 30,
    label: 'menuitems.guide.text',
    icon: 'bx bx-card',
    link: '/guide'
  },
  {
    id: 34,
    label: 'menuitems.store.text',
    icon: 'bx bx-store-alt',
    subItems: [
      {
        id: 35,
        label: 'menuitems.store.list.products',
        link: '/store/products',
        parentId: 34
      },
      {
        id: 36,
        label: 'menuitems.store.list.orders',
        link: '/store/orders',
        parentId: 34
      }
    ]
  },
  {
    id: 31,
    label: 'menuitems.faq.text',
    icon: 'bx bx-question-mark',
    link: '/faq'
  },
  {
    id: 10,
    label: 'menuitems.participants.text',
    icon: 'bxs-user-detail',
    link: '/participants'
  },
  {
    id: 29,
    label: 'menuitems.feed.text',
    icon: 'bxs-user-detail',
    link: '/feed'
  },
  {
    id: 11,
    label: 'menuitems.rooms.text',
    icon: 'bx-grid-alt',
    link: '/rooms'
  },
  {
    id: 12,
    label: 'menuitems.speakers.text',
    icon: 'bx bxs-user-voice',
    link: '/speakers'
  },
  {
    id: 13,
    label: 'menuitems.schedules.text',
    icon: 'bx bx-calendar-event',
    link: '/schedules'
  },
  {
    id: 20,
    label: 'menuitems.archives.text',
    icon: 'mdi mdi-archive',
    link: '/archives'
  },
  {
    id: 23,
    label: 'menuitems.videos.text',
    icon: 'mdi mdi-video',
    link: '/videos'
  },
  {
    id: 14,
    label: 'menuitems.partners.text',
    icon: 'bx bxs-group',
    link: '/partners'
  },
  {
    id: 15,
    label: 'menuitems.survey.text',
    icon: 'bx bx-happy',
    link: '/survey'
  },
  {
    id: 19,
    label: 'menuitems.gamification.text',
    icon: 'mdi mdi-trophy',
    link: '/gamification'
  },
  {
    id: 21,
    label: 'menuitems.raffle.text',
    icon: 'mdi mdi-gamepad-circle-down',
    link: '/raffle'
  },
  {
    id: 16,
    label: 'menuitems.report.text',
    isTitle: true
  },
  {
    id: 17,
    label: 'menuitems.reports.text',
    icon: 'bx bxs-report',
    link: '/reports'
  }
]
